/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import { Col, Row } from 'antd';
import cn from 'classnames';
// import _ from 'lodash';

import { dataLayerEvents } from 'common/utils';
import { Header } from 'client/components/common';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
// import { fileLink } from 'common/utils';

import arrow from 'assets/client/arrow.svg';
import * as css from './Intro.scss';
import { Footer } from '../../common/Footer';
import ModalTextBlock from './Components/ModalTextBlock/ModalTextBlock';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
|};

class Intro extends React.PureComponent<Props> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	componentDidMount() {
		const { onTransitionComplete } = this.props;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));

		const script = document.createElement('script');
		script.src = 'https://matriculation-certificate.firebaseapp.com/assets/embed.js';
		script.async = true;
		script.onload = () => window.embedWidget('bagrut-top', 'top?shrink=1');

		const script2 = document.createElement('script');
		script2.src = 'https://matriculation-certificate.firebaseapp.com/assets/embed.js';
		script2.onload = () => window.embedWidget('bagrut-comparison', 'comparison?shrink=1');
		script2.async = true;
		const { body } = document;
		if (body) {
			body.append(script, script2);
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	onLinkBlockClick = (e: SyntheticEvent<HTMLAnchorElement>) => {
		dataLayerEvents.articleLinkClick(e.currentTarget.href);
	};

	render() {
		const { className, transitionState } = this.props;
		return (
			<div className={cn(css.intro, css[transitionState], className)}>
				<Header />
				<div className={css.topInfo}>
					<div className={css.topInfoWrapper}>
						<h1>מפת הבגרויות של ישראל</h1>
						<div className={css.topText}>
							<p>
								פרויקט מיוחד של <span className={css.eng}>ynet </span>בשיתוף התנועה לחופש המידע חושף
								לראשונה את ציוני הבגרות של 2019, השנה שלפני הקורונה. כעת ניתן לבדוק מה הציון הממוצע בכל
								יישוב ובכל בית ספר, ולהשוות את הציונים מהשנים האחרונות. איזו עיר מובילה ומי בתחתית?
							</p>
						</div>
						<div className={css.topLinkBlock}>
							<a
								// eslint-disable-next-line max-len
								href="https://ynet-images1.yit.co.il/picserver5/wcm_upload_files/2021/03/13/ry8sAccm00/____________2019.xlsx"
								className={css.topLink}
							>
								<span className={css.eng2}>Excel </span> לחצו כאן לפתיחת קובץ
							</a>
							<p>?רוצים לראות את ציוני הבגרות של בית הספר שלכם בשנת 2019</p>
						</div>
						<div className={css.linkBlock}>
							<a
								href="https://m.ynet.co.il/articles/59065310?externalurl=true"
								className={css.linkButton}
								target="_blank"
								rel="noreferrer"
								onClick={this.onLinkBlockClick}
							>
								<img src={arrow} alt="arrow" className={css.arrowImg} />
								לניתוח המלא - לחצו כאן
							</a>
						</div>
					</div>
				</div>
				<div className={css.iframe__block}>
					<Row justify="center" type="flex" gutter={24}>
						<Col span={24} order={2} sm={{ span: 10, order: 1 }}>
							<div className={css.iframe__textBlock}>
								<h3 className={css.iframe__title}>המצטיינים של 2019</h3>
								<h4 className={css.iframe__subTitle}>?איך חישבנו</h4>
								<ModalTextBlock
									text="כדי לתת משקל סטטיסטי סביר בדירוגי הצמרת והתחתית של בחינות הבגרות במקצועות החובה המשותפים לכלל הזרמים במערכת החינוך, הוכנסו לדירוג הזה רק יישובים שבהם ניגשו יותר מ-200 תלמידים לבחינה..."
									fullText="כדי לתת משקל סטטיסטי סביר בדירוגי הצמרת והתחתית של בחינות הבגרות במקצועות החובה המשותפים לכלל הזרמים במערכת החינוך, הוכנסו לדירוג הזה רק יישובים שבהם ניגשו יותר מ-200 תלמידים לבחינה
המקצועות שבהם חושב המדד: מתמטיקה (כל יחידה), אנגלית (כל היחידות), אזרחות (2 יחידות), ספרות (2 יחידות) והבעה עברית (2 יחידות). בשאר המקצועות הדירוג נעשה בהתאם לכלל היישובים שנתוניהם התקבלו ממשרד החינוך. בחלק מהמקצועות לא מופיע דירוג בשל העובדה שהתקבלו נתונים של פחות מ-20 יישובים.
"
									title="המצטיינים של 2019"
									subTitle="?איך חישבנו"
								/>
							</div>
						</Col>
						<Col span={24} order={1} sm={{ span: 10, order: 2 }}>
							<div className={css.iframe__textBlock}>
								<h3 className={css.iframe__title}>השוואה בין יישובים לאורך שנים</h3>
								<h4 className={css.iframe__subTitle}>?איך חישבנו</h4>
								<ModalTextBlock
									// eslint-disable-next-line max-len
									text="בהתאם למדיניות עיבוד הנתונים של משרד החינוך, לא התקבלו נתונים על בתי ספר שבהם נבחנו פחות מ-11 תלמידים בבחינה מסוימת (לדוגמה בבחינת 5 יחידות מתמטיקה) חישוב הממוצע בכל אחד מהיישובים נעשה כך..."
									fullText="בהתאם למדיניות עיבוד הנתונים של משרד החינוך, לא התקבלו נתונים על בתי ספר שבהם נבחנו פחות מ-11 תלמידים בבחינה מסוימת (לדוגמה בבחינת 5 יחידות מתמטיקה).
חישוב הממוצע בכל אחדד מהיישובים נעשה כך:
איגדנו בנפרד את הציון הממוצע בכל בית ספר והכפלנו אותו במספר הנבחנים שניגשו באותו מוסד לימודים. חיברנו את התוצאה שהתקבלה מכל בתי הספר של אותו יישוב וחילקנו במספר הנבחנים שניגשו לבחינה באותו יישוב – וכך התקבל הממוצע.
"
									title="השוואה בין יישובים לאורך שנים"
									subTitle="?איך חישבנו"
								/>
							</div>
						</Col>
						<Col span={24} order={4} sm={{ span: 10, order: 3 }}>
							<div className={css.iframe__wrapper}>
								<div id="bagrut-top"></div>
							</div>
						</Col>
						<Col span={24} sm={{ span: 10, order: 4 }} xs={{ order: 1 }}>
							<div className={css.iframe__wrapper}>
								<div id="bagrut-comparison"></div>
							</div>
						</Col>
					</Row>
				</div>
				<Footer />
			</div>
		);
	}
}

export default withTransition(Intro);
