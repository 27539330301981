// @flow

import * as React from 'react';
import classNames from 'classnames';
import Socials from 'client/components/common/Socialls';
import logo from 'assets/client/ynet.png';
import * as css from './Header.scss';

type Props = {
	className?: string,
};

const Header = ({ className }: Props) => {
	return (
		<div className={classNames(css.header, className)}>
			<Socials />
			<a href="https://www.ynet.co.il" className={css.logo} target="_blank" rel="noreferrer">
				<img src={logo} alt="logo" />
			</a>
		</div>
	);
};

Header.defaultProps = {
	className: '',
};

export { Header };
