/* eslint-disable max-len */
// @flow
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	FACEBOOK: 'Facebook',
	TWITTER: 'Twitter',
};

export const COLLECTIONS = {
	CATEGORIES: 'CATEGORIES',
};

export const APP_CONFIG = {};
