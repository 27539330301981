/* eslint-disable jsx-a11y/iframe-has-title */
// @flow
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// flow-disable-next-line
import 'antd/dist/antd.less';
import 'client/styles/index.scss';
import App from './App';

type Props = {
	store: any,
};

class Root extends React.PureComponent<Props> {
	render() {
		const { store } = this.props;

		return (
			<Provider store={store}>
				<BrowserRouter>
					<HelmetProvider>
						<App />
					</HelmetProvider>
				</BrowserRouter>
			</Provider>
		);
	}
}

export default Root;
