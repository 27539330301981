// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import { Modal, Icon } from 'antd';
import cn from 'classnames';
import * as css from './ModalTextBlock.scss';

type Props = {|
	className?: string,
	text: string,
	fullText: string,
	title: string,
	subTitle: string,
|};

type State = {|
	isModalVisible: boolean,
|};

class ModalTextBlock extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			isModalVisible: false,
		};
	}
	showModal = () => {
		this.setState({
			isModalVisible: true,
		});
	};

	onCancel = () => {
		this.setState({
			isModalVisible: false,
		});
	};

	modalRender = () => {
		return <div>lol</div>;
	};
	render() {
		const { className, title, subTitle, text, fullText } = this.props;
		const { isModalVisible } = this.state;

		return (
			<div className={cn([css.iframe__textBlock, className])}>
				<p className={css.iframe__text}>{text}</p>
				<button type="button" className={css.iframe__moreButton} onClick={this.showModal}>
					קראו עוד
				</button>

				<Modal
					footer={null}
					onCancel={this.onCancel}
					visible={isModalVisible}
					bodyStyle={{ textAlign: 'right', border: '1px solid red', width: '100%' }}
					maskStyle={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
					closeIcon={<Icon type="close" style={{ color: 'red' }} />}
					width={760}
				>
					<div>
						<h3 className={css.title}>{title}</h3>
						<p className={css.subTitle}>{subTitle}</p>
						<p className={css.text}>{fullText}</p>
					</div>
				</Modal>
			</div>
		);
	}
}

export default ModalTextBlock;
