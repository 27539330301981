// @flow
import * as React from 'react';
import classNames from 'classnames';
import * as css from './Footer.scss';

type Props = {
	className?: string,
};

const Footer = ({ className }: Props) => {
	return (
		<div className={classNames(css.footer, className)}>
			<p className={css.footer__text}>
				<span className={css.bold}>כתבת:</span> תמר טרבלסי חדד | <span className={css.bold}>עריכה:</span> יהונתן
				בניה
			</p>
		</div>
	);
};

Footer.defaultProps = {
	className: '',
};

export { Footer };
