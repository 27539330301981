/* eslint-disable max-len */
// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet-async';
// import _ from 'lodash';

// import { CAN_USE_DOM } from 'common/constants';
import configProd from 'config/production';
import { detectIE } from 'common/utils/detect-ie';
// import { parseURL } from 'common/utils/parse-url';

import Intro from 'client/components/pages/Intro';

import faviconImg from 'assets/favicon.png';
// import generalShareImg from 'assets/client/share.png';
import { ROOT_ROUTE } from 'client/constants';
import * as css from './App.scss';
// import 'antd/dist/antd.css';

type Props = {|
	// history: RouterHistory,
	// location: RouterLocation,
	isBusy?: boolean,
|};

class App extends React.PureComponent<Props> {
	static defaultProps = {
		isBusy: true,
	};

	async componentDidMount() {
		const isIE = detectIE();

		// tracker.init('UA-142761482-39');
		// this.props.checkAuthState();

		if (!this.props.isBusy) {
			this.removeRootPreloader();
		}

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');
		}
	}

	async componentDidUpdate(prevProps: Props) {
		if (!this.props.isBusy && prevProps.isBusy) {
			this.removeRootPreloader();
		}
	}

	renderHelmet = () => {
		// const { settings } = this.props;
		const title = 'ynet - מפת הבגרויות של ישראל';
		const description =
			'פרויקט מיוחד של ynet, בשיתוף התנועה לחופש המידע, חושף לראשונה את ציוני הבגרות של 2019, השנה שלפני הקורונה. כעת ניתן לבדוק מה הציון הממוצע בכל יישוב ובית ספר, ולהשוות את הציונים מהשנים האחרונות. איזו עיר מובילה ומי בתחתית?';
		const image = `${configProd.domains.client.hostname}/assets/client/share.jpg`;

		// title = _.get(settings.texts, 'og.title', '');
		// description = _.get(settings.texts, 'og.description', '');
		// image = '/assets/client/share.png';

		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: image },
				]}
			/>
		);
	};

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 1000);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	render() {
		return (
			<div id="app" className={css.app}>
				{this.renderHelmet()}
				<div className={css.content}>
					<Route exact path={ROOT_ROUTE}>
						{({ match }) => <Intro in={!!match} />}
					</Route>
				</div>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	isBusy: false,
});

const mapDispatch = {};

export default withRouter(connect(mapState, mapDispatch)(App));
