// @flow
import { SHARE_CONSTANTS } from 'client/constants';
import _ from 'lodash';

export const share = (socialType: string, shareType: 'general' | 'detailed', texts: any, cb: Function = _.noop) => {
	const titleProp = document.querySelector('meta[property="og:title"]');
	let title: ?string = titleProp ? titleProp.getAttribute('content') : '';
	const descriptionProp = document.querySelector('meta[property="og:description"]');
	let description: ?string = descriptionProp ? descriptionProp.getAttribute('content') : '';
	const encode = encodeURIComponent;
	const isMobile = window.matchMedia('(max-width: 551px)').matches;
	let domain = window.location.href;

	if (shareType === 'general') {
		domain = 'https://bagrut-external-site.firebaseapp.com/';
		title = _.get(texts, 'website.ogTitle', '');
		description = _.get(texts, 'website.ogDescription', '');
	}

	if (socialType === SHARE_CONSTANTS.FACEBOOK) {
		const fbURL = 'http://www.facebook.com/sharer.php?u=';

		window.open(`${fbURL}${domain}`, 'sharer', 'width=555,height=600');
	}

	if (socialType === SHARE_CONSTANTS.WHATSAPP) {
		if (title && description) {
			if (!isMobile) {
				window.open(
					`http://web.whatsapp.com/send?text=${encode(`${title}\n${description}\n${domain}`)}`,
					'_blank'
				);
			} else {
				window.location.href = `whatsapp://send?text=${encode(`${title}\n${description}\n${domain}`)}`;
			}
		}
	}

	if (socialType === SHARE_CONSTANTS.TWITTER) {
		const twURL = 'https://twitter.com/intent/tweet?url=';
		if (title && description) {
			const shareWindow = window.open(
				`
			${twURL}${encode(`${domain}\n${title}\n${description}`)}`,
				'ShareOnTwitter',
				'width=555,height=600'
			);
			shareWindow.opener = null;
		}
	}

	cb();
};
