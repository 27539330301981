/* eslint-disable max-len */
// @flow

import * as React from 'react';
import classNames from 'classnames';

import { share } from 'client/utils/share';
import { dataLayerEvents } from 'common/utils';
import { SHARE_CONSTANTS } from 'client/constants';
import fbIcon from 'assets/client/facebook-icon.png';
import twitter from 'assets/client/twitter-icon.png';
import whatsappIcon from 'assets/client/whatsapp-icon.png';
import * as css from './Socials.scss';
import AccessibilityIcon from '../AccessibilityIcon';

type Props = {
	className?: string,
};

type State = {
	isOpen: boolean,
};

export default class Socials extends React.Component<Props, State> {
	containerRef: { current: null | HTMLDivElement } = React.createRef();

	static defaultProps = {
		className: '',
	};

	state = {
		isOpen: false,
	};

	toggleOpenMenu = (e: SyntheticEvent<HTMLDivElement>) => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
		}));
	};

	onShare = (e: SyntheticEvent<HTMLButtonElement>) => {
		const type = e.currentTarget.id;
		console.info(type);

		dataLayerEvents.shareClick('Site Head', type);
		share(
			type,
			'detailed'
			// eslint-disable-next-line max-len
		);
	};

	render() {
		const { className } = this.props;

		return (
			<div className={classNames(css.socials, className, this.state.isOpen && css.open)} ref={this.containerRef}>
				<div className={css.btnsWrap}>
					{/* <button type="button" className={css.accessabilityBtn} onClick={this.onShare}>
							<img src={accessability} alt="" />
						</button> */}
					<AccessibilityIcon />
					<button
						type="button"
						className={css.facebookBtn}
						id={SHARE_CONSTANTS.FACEBOOK}
						onClick={this.onShare}
					>
						<img src={fbIcon} alt="" />
					</button>

					<button
						type="button"
						className={css.twitterBtn}
						id={SHARE_CONSTANTS.TWITTER}
						onClick={this.onShare}
					>
						<img src={twitter} alt="" />
					</button>
					<button
						type="button"
						className={css.whatsAppBtn}
						id={SHARE_CONSTANTS.WHATSAPP}
						onClick={this.onShare}
					>
						<img src={whatsappIcon} alt="" />
					</button>
				</div>
			</div>
		);
	}
}
