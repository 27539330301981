// @flow

export const dataLayerEvents = {
	articleLinkClick: (label: string) =>
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_event',
			Category: 'Navigation',
			Action: 'Matriculation Map - Click to article',
			Label: label,
		}),

	shareClick: (action: string, label: string) =>
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_event',
			Category: 'Social',
			Action: `Share From ${action}`,
			Label: label,
		}),
};
