// @flow
import { connect } from 'react-redux';

// eslint-disable-next-line import/extensions
import Intro from './Intro';

const mapState = (state: ClientStore) => ({});
const mapDispatch = {};

export default connect(mapState, mapDispatch)(Intro);
